import React from "react";
import Carousel from "react-bootstrap/cjs/Carousel";
import styled from "styled-components";
import mq from "../../styles/media-query";
import {StyledCarousel, Headline, SubIMG} from "../layout";

const CaroItem = styled.div`

    h3 {
        font-size: 1.2rem;
    }
    
    p {
        font-size: 0.9rem;
    }

    &.row > div {
        display: flex;
        align-items: center;
        flex-flow: no-wrap;
        margin-bottom: 0;
        height: 200px;
        &:not(:first-child) {
            margin-top: 1.4rem;
            ${mq.md`
                margin-top: 0;
            `}
        }
        img {
            width: 100%;
            height: 100%;
            overflow: hidden;
            object-fit: cover;
            
            + div {
                display: none;
                padding: 0 24px;
                
            }
        }
    }
`;

export default function CarouselPurchases() {
    return (
        <StyledCarousel>
            <Headline><h3 style={{fontSize: 'unset'}}>Properties We Have Purchased</h3></Headline>
            <Carousel>
            <Carousel.Item>
                <CaroItem className={`row`}>
                    <div className={`col-lg-4`}>
                        <img src={require("../../assets/img/c1.jpg")} alt="" />
                        <div>
                            <h3>Lorem Ipsum Dolor Sit Amet</h3>
                            <p>$600,000 | 30 Days</p>
                            </div>
                    </div>
                    <div className={`col-lg-4`}>
                        <img src={require("../../assets/img/c2.jpg")} alt="" />
                        <div>
                            <h3>Lorem Ipsum Dolor Sit Amet</h3>
                            <p>$600,000 | 30 Days</p>
                        </div>
                    </div>
                    <div className={`col-lg-4`}>
                        <img src={require("../../assets/img/c3.jpg")} alt="" />
                        <div>
                            <h3>Lorem Ipsum Dolor Sit Amet</h3>
                            <p>$600,000 | 30 Days</p>
                        </div>
                    </div>
                </CaroItem>
            </Carousel.Item>
            <Carousel.Item>
                <CaroItem className={`row`}>
                    <div className={`col-lg-4`}>
                        <SubIMG src={require("../../assets/img/c4.jpg")} alt="" />
                        <div>
                            <h3>Lorem Ipsum Dolor Sit Amet</h3>
                            <p>$600,000 | 30 Days</p>
                        </div>
                    </div>
                    <div className={`col-lg-4`}>
                        <SubIMG src={require("../../assets/img/c5.jpg")} alt="" />
                        <div>
                            <h3>Lorem Ipsum Dolor Sit Amet</h3>
                            <p>$600,000 | 30 Days</p>
                        </div>
                    </div>
                    <div className={`col-lg-4`}>
                        <SubIMG src={require("../../assets/img/c6.jpg")} alt="" />
                        <div>
                            <h3>Lorem Ipsum Dolor Sit Amet</h3>
                            <p>$600,000 | 30 Days</p>
                        </div>
                    </div>
                </CaroItem>
            </Carousel.Item>
            <Carousel.Item>
                <CaroItem className={`row`}>
                    <div className={`col-lg-4`}>
                        <SubIMG src={require("../../assets/img/c8.jpg")} alt="" />
                        <div>
                            <h3>Lorem Ipsum Dolor Sit Amet</h3>
                            <p>$600,000 | 30 Days</p>
                        </div>
                    </div>
                    <div className={`col-lg-4`}>
                        <SubIMG src={require("../../assets/img/c9.jpg")} alt="" />
                        <div>
                            <h3>Lorem Ipsum Dolor Sit Amet</h3>
                            <p>$600,000 | 30 Days</p>
                        </div>
                    </div>
                    <div className={`col-lg-4`}>
                        <SubIMG src={require("../../assets/img/c10.jpg")} alt="" />
                        <div>
                            <h3>Lorem Ipsum Dolor Sit Amet</h3>
                            <p>$600,000 | 30 Days</p>
                        </div>
                    </div>
                </CaroItem>
            </Carousel.Item>
            <Carousel.Item>
                <CaroItem className={`row`}>
                    <div className={`col-lg-4`}>
                        <SubIMG src={require("../../assets/img/c11.jpg")} alt="" />
                        <div>
                            <h3>Lorem Ipsum Dolor Sit Amet</h3>
                            <p>$600,000 | 30 Days</p>
                        </div>
                    </div>
                    <div className={`col-lg-4`}>
                        <SubIMG src={require("../../assets/img/c12.jpg")} alt="" />
                        <div>
                            <h3>Lorem Ipsum Dolor Sit Amet</h3>
                            <p>$600,000 | 30 Days</p>
                        </div>
                    </div>
                    <div className={`col-lg-4`}>
                        <SubIMG src={require("../../assets/img/c13.jpg")} alt="" />
                        <div>
                            <h3>Lorem Ipsum Dolor Sit Amet</h3>
                            <p>$600,000 | 30 Days</p>
                        </div>
                    </div>
                </CaroItem>
            </Carousel.Item>
            <Carousel.Item>
                <CaroItem className={`row`}>
                    <div className={`col-lg-4`}>
                        <SubIMG src={require("../../assets/img/c14.jpg")} alt="" />
                        <div>
                            <h3>Lorem Ipsum Dolor Sit Amet</h3>
                            <p>$600,000 | 30 Days</p>
                        </div>
                    </div>
                    <div className={`col-lg-4`}>
                        <SubIMG src={require("../../assets/img/c15.jpg")} alt="" />
                        <div>
                            <h3>Lorem Ipsum Dolor Sit Amet</h3>
                            <p>$600,000 | 30 Days</p>
                        </div>
                    </div>
                    <div className={`col-lg-4`}>
                        <SubIMG src={require("../../assets/img/c16.jpg")} alt="" />
                        <div>
                            <h3>Lorem Ipsum Dolor Sit Amet</h3>
                            <p>$600,000 | 30 Days</p>
                        </div>
                    </div>
                </CaroItem>
            </Carousel.Item>
            </Carousel>
        </StyledCarousel>
    )
}