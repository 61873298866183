import React from "react"
import styled from "styled-components"
import { Helmet } from "react-helmet"
import AsSeenOn from "../components/asSeenOn/asSeenOn"
import Layout, { Headline, ThreeUp } from "../components/layout"
import Telephone from "../components/telephone/telephone"
import Video from "../components/video/video"
import CarouselPurchases from "../components/carousels/purchases"
import bg from "../assets/img/hero.jpg"
import VsTable from "../components/vsTable/vsTable"
import mq from "../styles/media-query"
import {
  AudioCopy,
  AudioInfoLink,
  AudioTitle,
} from "../components/audioPlayer/audioPlayer"

const ProcessBloc = styled.div`
  background: var(--brandSecondary);
  background: linear-gradient(to bottom, #384a6e 0%, var(--brandSecondary) 15%);
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
  color: white;
  border-top: 1px solid white;

  h2 {
    color: white;
  }

  .container > div:nth-child(2) {
    padding-bottom: 2rem;
  }
`

const ProcessColumn = styled.div`
  text-align: center;
  font-size: 16px;
  font-weight: 700;

  ${mq.sm`
        font-size: 18px;
    `}
  ${mq.lg`
        font-size: 20px;
    `}

    img {
    margin: 0 auto;
  }

  i {
    font-size: 40px;
    margin-bottom: 2rem;
    color: var(--brandPrimary);
    ${mq.sm`
            font-size: 60px;
        `}
  }
`

const CenterText = styled.div`
  text-align: center;
  > p {
    font-size: 1.2rem;
  }
`

export default function Home() {
  return (
    <Layout
      bg={bg}
      header={"RELIABLE BUYERS YOU CAN TRUST!"}
      subheader={
        "We Purchase Homes AS-IS!                          ANY Property - ANY Condition                             Southern California's Leading Home Buyers!"
      }
    >
      <Helmet>
        <title>Southern California's Trusted Cash Home Buyers | DB</title>
        <meta
          name="description"
          content="With 30+ years in the real estate industry, our team of California cash home buyers can help you sell your house fast for a no-obligation cash offer. Call 844-242-SELL!"
        />
      </Helmet>

      <ProcessBloc>
        <div className={`container`}>
          <CenterText>
            <Headline>
              <h2 style={{ fontSize: "unset" }}>OUR FAST AND EASY PROCESS: </h2>
            </Headline>
            <p>
              With over 30yrs experience in the Real Estate Industry, We are
              here to help!
            </p>
          </CenterText>

          <ThreeUp className={`row justify-content-center`}>
            <ProcessColumn className={`col-md-4`}>
              <i className={`fas fa-phone`} />
              <p>
                CALL OR SUBMIT YOUR INFORMATION ON OUR WEBSITE. LET US GET TO
                KNOW YOUR HOME!
                <br />
                <Telephone />
              </p>
            </ProcessColumn>
            <ProcessColumn className={`col-md-4`}>
              <i className={`fas fa-file-alt`} />
              <p>
                RECIEVE A NO-OBLIGATION, CASH OFFER! ONCE YOU ACCEPT OUR OFFER,
                YOU CHOOSE YOUR CLOSING DATE!
              </p>
            </ProcessColumn>
            <ProcessColumn className={`col-md-4`}>
              <i className={`fas fa-calendar-check`} />
              <p>
                WE OPEN ESCROW & YOU GET PAID CASH IN AS FAST AS 10 DAYS! No
                Repairs & Never any Fees!
              </p>
            </ProcessColumn>
          </ThreeUp>
        </div>
      </ProcessBloc>
      <div className={`container`}>
        <div className="row">
          <div className="col-md-6">
            <Video url={"https://www.youtube.com?v=duHrB_4JRa4"} />
            <AudioTitle>Bill Handel</AudioTitle>
            <AudioCopy>
              Listen to what Top Talk Radio personality Bill Handel of KFI 640AM
              "Handel on the Law Show" had to say about Direct Buyers &mdash;{" "}
              <AudioInfoLink
                target="_blank"
                href="https://kfiam640.iheart.com/featured/bill-handel"
              >
                Bill Handel Saturday Mornings
              </AudioInfoLink>
            </AudioCopy>
          </div>
          <div className="col-md-6">
            <Video url={"https://www.youtube.com&v=5tQgEQAMCzE"} />
            <AsSeenOn />
          </div>
        </div>
        <Headline>
          <h2 style={{ fontSize: "unset" }}>
            Selling to Direct Buyers vs. the Traditional Selling Process
          </h2>
        </Headline>
        <div className={`row`}>
          <div className="col">
            <VsTable />
          </div>
        </div>
        <CarouselPurchases />
      </div>
    </Layout>
  )
}
